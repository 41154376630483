import React from 'react';
import { FaHome, FaUser, FaEnvelope, FaLinkedin, FaGithub } from 'react-icons/fa';
import { motion } from 'framer-motion';

const Main = () => {
    return (
        <div className="min-h-screen bg-gradient-to-r from-gray-900 to-gray-800 text-white">
            {/* Navigation Bar */}
            <nav className="p-4 bg-gray-800 bg-opacity-75 shadow-lg">
                <div className="container mx-auto flex justify-between items-center">
                    <motion.div
                        initial={{ opacity: 0, x: -50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                        className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-blue-400"
                    >
                     
                    </motion.div>
                    <ul className="flex space-x-6">
                        <motion.li whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                            <a href="#home" className="hover:text-gray-300 flex items-center">
                                <FaHome className="mr-2" /> Home
                            </a>
                        </motion.li>
                        <motion.li whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                            <a href="#about" className="hover:text-gray-300 flex items-center">
                                <FaUser className="mr-2" /> About
                            </a>
                        </motion.li>
                        <motion.li whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                            <a href="#contact" className="hover:text-gray-300 flex items-center">
                                <FaEnvelope className="mr-2" /> Contact
                            </a>
                        </motion.li>
                    </ul>
                </div>
            </nav>

            {/* Home Section */}
            <section id="home" className="container mx-auto px-4 py-20 text-center">
                {/* Profile Picture Placeholder */}
                <motion.div
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                    className="w-40 h-40 mx-auto mb-6 rounded-full overflow-hidden border-4 border-gray-700 hover:border-purple-500 transition-all duration-300"
                >
                    <img
                        src={require('../assets/profile-pic.jpg')} // Replace with your picture URL
                        alt="Profile"
                        className="w-full h-full object-cover"
                    />
                </motion.div>

                <motion.h1
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    className="text-5xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-blue-400"
                >
                    Lauri Törmä
                </motion.h1>
                <motion.p
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 0.2 }}
                    className="text-xl mb-8 text-gray-300"
                >
                    IT-Specialist
                </motion.p>
            </section>

            {/* About Section */}
            <section id="about" className="container mx-auto px-4 py-20 text-center">
                <motion.h2
                    initial={{ opacity: 0, y: -50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    className="text-4xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-blue-400"
                >
                    About Me
                </motion.h2>
                <motion.p
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 0.2 }}
                    className="text-xl mb-8 mx-auto max-w-2xl text-gray-300"
                >
                    I am currently working as a Junior RPA Maintenance Specialist at Digital Workforce plc,
                    where my primary focus is on maintaining and optimizing Blue Prism robotic automation solutions.
                    My responsibilities include managing service requests, handling my work queue efficiently,
                    and ensuring the smooth operation of automation systems by collaborating with various stakeholders.
                    I play an active role in troubleshooting and resolving issues to keep our customers’ automations running seamlessly.
                    My work is guided by ITIL practices, ensuring that I provide high-quality,
                    reliable service and contribute to continuous improvement in automation processes.
                </motion.p>
            </section>

            {/* Contact Section */}
            <section id="contact" className="container mx-auto px-4 py-20 text-center">
                <motion.h2
                    initial={{ opacity: 0, y: -50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    className="text-4xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-blue-400"
                >
                    Contact Me
                </motion.h2>
                <motion.p
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 0.2 }}
                    className="text-xl mb-8 mx-auto max-w-2xl text-gray-300"
                >
                    <div className="flex justify-center space-x-4">
                        <motion.a
                            whileHover={{ scale: 1.2 }}
                            whileTap={{ scale: 0.9 }}
                            href="https://www.linkedin.com/in/lauri-torma/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="hover:text-gray-300"
                        >
                            <FaLinkedin size={24} />
                        </motion.a>
                        <motion.a
                            whileHover={{ scale: 1.2 }}
                            whileTap={{ scale: 0.9 }}
                            href="https://github.com/lauritorma"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="hover:text-gray-300"
                        >
                            <FaGithub size={24} />
                        </motion.a>
                    </div>
                </motion.p>
            </section>

            {/* Footer */}
            <footer className="p-6 bg-gray-800 bg-opacity-75 mt-auto shadow-lg">
                <div className="container mx-auto text-center">
                    <p className="mb-4 text-gray-300">© 2025 Lauri Törmä. All rights reserved.</p>
                    
                </div>
            </footer>
        </div>
    );
};

export default Main;